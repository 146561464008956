<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12" :md="card ? 12 : 8">
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-form class="d-flex align-center" ref="form" @submit.prevent="get_usuario">
                    <v-row>
                      <v-col class="body-1">
                        Usuario
                        <v-text-field
                          v-model.trim="usuario"
                          :loading="load && card"
                          :readonly="card"
                          :filled="card"
                          :autofocus="!card"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-btn
                      v-if="!card"
                      color="info"
                      class="ml-4 mb-1"
                      type="submit"
                      :loading="load"
                    >
                      <v-icon left>fas fa-search</v-icon>
                      Buscar
                    </v-btn>
                  </v-form>
                </v-col>
                <v-col
                  v-if="nombre"
                  cols="12" md="6"
                  class="d-flex justify-end align-center"
                >
                  <div class="body-1">
                    <span>Estado: </span>
                    <span
                      :class="`${color}--text`"
                    ><strong>{{ estado }}</strong></span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="nombre" cols="12" class="d-flex justify-end pb-2">
        <v-btn
          color="warning"
          class="mr-3 mt-2"
          @click="dialog_clave = true"
        >
          <v-icon left>fas fa-key</v-icon>
          Cambiar clave
        </v-btn>
        <BtnConfirmar
          nombre="Bloquear"
          clase="mr-3 mt-2"
          color="error"
          icono="fas fa-lock"
          :texto="`¿Desea bloquear al usuario <strong>${nombre}</strong>?`"
          :loading="load_bloquear"
          @action="bloquear"
        />
        <BtnConfirmar
          nombre="Desbloquear"
          clase="mr-3 mt-2"
          color="success"
          icono="fas fa-unlock-alt"
          :texto="`¿Desea desbloquear al usuario <strong>${nombre}</strong>?`"
          :loading="load_desbloquear"
          @action="desbloquear"
        />
      </v-row>
    </v-col>
    <CambiarClave
      v-model="dialog_clave"
      title="Cambiar clave"
      :text="`Por favor ingresar una nueva clave para el usuario <strong>${nombre}</strong>:`"
      :user="nombre"
      :admin="true"
      :estado.sync="estado"
      @action="set_color()"
    />
  </v-row>
</template>

<script>
import CambiarClave from '../../components/CambiarClave'
import BtnConfirmar from '../../components/util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      load_bloquear: false,
      load_desbloquear: false,
      dialog_clave: false,
      usuario: '',
      nombre: '',
      estado: '',
      color: '',
    }
  },
  created () {
    this.usuario = this.user
    if (this.card) {
      this.get_usuario()
    }
  },
  components: {
    CambiarClave,
    BtnConfirmar,
  },
  props: {
    card: {
      type: Boolean,
      default: false,
    },
    user: {
      type: String,
      default: '',
    },
    /**
     *  este prop sirve para refrescar los datos cuando se selecciona otro
     *  item de la lista (solo se usa en el componente ModalUsuario)
     */
    refrescar: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    usuario () {
      if (this.nombre) {
        this.nombre = ''
        this.estado = ''
      }
    },
    refrescar (val) {
      if (val) {
        this.usuario = this.user
        this.get_usuario()
      } else {
        this.usuario = ''
        this.nombre = ''
        this.estado = ''
        this.color = ''
      }
    }
  },
  methods: {
    async get_usuario () {
      if (this.usuario !== '') {
        this.load = true
        this.$store.state.load_user_modal = true
        await this.$store.dispatch('verificar_permiso', this.usuario)
          .then((data) => {
            this.nombre = data.usuario.nombre
            this.estado = data.usuario.estado
            this.set_color()
          })
          .catch(error => {
            this.nombre = ''
            this.estado = ''
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.load = false
        this.$store.state.load_user_modal = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese un usuario válido',
          color: 'warning',
        })
      }
    },
    async bloquear () {
      this.load_bloquear = true
      this.$store.state.load_user_modal = true
      await this.$store.dispatch('bloquear_usuario', this.nombre)
        .then((data) => {
          this.$store.dispatch('show_snackbar', {
            text: data.message,
            color: 'success',
          })
          this.update_estado()
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load_bloquear = false
      this.$store.state.load_user_modal = false
    },
    async desbloquear () {
      this.load_desbloquear = true
      this.$store.state.load_user_modal = true
      await this.$store.dispatch('desbloquear_usuario', this.nombre)
        .then((data) => {
          this.$store.dispatch('show_snackbar', {
            text: data.message,
            color: 'success',
          })
          this.update_estado()
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load_desbloquear = false
      this.$store.state.load_user_modal = false
    },
    async update_estado () {
      await this.$store.dispatch('get_user_estado', this.nombre)
        .then((data) => {
          this.estado = data
          this.set_color()
        })
    },
    set_color () {
      if (this.estado.indexOf('BLO') >= 0) {
        this.color = 'red'
      } else if (this.estado.indexOf('EXP') >= 0) {
        this.color = 'amber'
      } else if (this.estado.indexOf('ACT') >= 0) {
        this.color = 'success'
      }
    }
  }
}
</script>